<template>
  <el-card class="orderIndex">
    <el-form slot="header" class="searchFrom square-input-warp" size="small" :inline="true"
             lable-width="80px"
             :model="searchForm" ref="searchForms">

      <el-form-item label="订单编号:" prop="orderNo">
        <el-input v-model="searchForm.orderNo" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="下单时间:" prop="createTimeLimit">
        <el-date-picker
          v-model="searchForm.createTimeLimit"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-button size="small" @click="onSearchClick" type="primary">搜索</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <div class="operation-btn-group">
        <template v-for="operation in orderStatusOperation">
          <el-button
            @click="changeOrderStatus(operation.value)"
            plain size="mini" :key="operation.name"
            :class="['sctp-square',{active: activeOperation(operation.value)}]"
          >
            {{ operation.name }}
          </el-button>
        </template>
      </div>
    </el-form>
    <div>
      <el-row type="flex" align="middle" class="pad-tb5 sctp-pad-lr15">
        <el-col :span="1">
          <el-checkbox @change="setAllStatus" :indeterminate="indeterminate" :value="selectAll">全选
          </el-checkbox>
        </el-col>
        <el-col :span="10">
          <div class="sctp-tc">店铺宝贝</div>
        </el-col>
        <el-col :span="2">
          <div class="sctp-tc">单价</div>
        </el-col>
        <el-col :span="2">
          <div class="sctp-tc">状态</div>
        </el-col>
        <el-col :span="2">
          <div class="sctp-tc">数量</div>
        </el-col>
        <el-col :span="3">
          <div class="sctp-tc">小计</div>
        </el-col>
        <el-col :span="2">
          <div class="sctp-tc">操作</div>
        </el-col>
      </el-row>
      <div v-loading="isLoadData" style="min-height: 100px;">
        <template v-if="orderData && orderData.length > 0">
          <template v-for="order in orderData">
          <div class="order-item" :key="order.orderid">
            <div
              class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
              <div style="margin-right: 30px;">订单号：{{ order.ordernum }}</div>
              <div>提交时间：{{ dateToString(order.createtime) }}</div>
            </div>
            <template v-for="shopGroup in order.goods">
              <div :key="shopGroup.shopId">
                <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                  <el-image
                    class="sctp-mar-r15"
                    style="width: 20px; height: 20px"
                    :src="shopGroup.shopLogo"
                    fit="cover"></el-image>
                  <div>店铺：{{ shopGroup.shopName }}</div>
                </div>
                <div class="goods-list">
                  <el-row class="sctp-pad15 goods-item"
                          v-for="goods in shopGroup.goods"
                          :key="goods.goodsid">
                    <el-col :span="1">
                      <el-checkbox v-model="goods.selected"></el-checkbox>
                    </el-col>
                    <el-col :span="10">
                      <div class="">
                        <div class="sctp-flex">
                          <el-image
                            class="sctp-mar-r15"
                            style="width: 80px; height: 80px"
                            :src="goods.goodsimg"
                            fit="cover"></el-image>
                          <div class="sctp-flex-item-1">
                            <router-link target="_blank" :to="{
                                2: `/sourcecode/view/${goods.goodsid}`,
                                4: `/design/view/${goods.goodsid}`,
                                6: `/techdocs/view/${goods.goodsid}`
                              }[order.ordertype]">
                              <div>
                                {{
                                  {
                                    2: `源码`,
                                    4: `设计`,
                                    6: `文档`
                                  }[order.ordertype]
                                }}编号：#{{ goods.goodsid }} {{ goods.goodsname }}
                              </div>
                            </router-link>
                          </div>
                        </div>
                        <div>
                          <template v-if="order.orderGoodsList[0].copyright_price != null">
                            <div class="pad-tb5">版权费用：{{
                                order.orderGoodsList[0].copyright_price
                              }}￥
                            </div>
                          </template>
                          <template v-if="order.orderGoodsList[0].install_price != null">
                            <div class="pad-tb5">提供远程安装服务：{{
                                order.orderGoodsList[0].install_price
                              }}￥
                            </div>
                          </template>
                        </div>
                        <template v-if="goods.productType === 4">
                          <div class="fc-info pad-tb5">
                            ({{ goods.design.type }}-{{ goods.design.subType }})/
                            <template v-if="goods.design.devetools">{{
                                goods.design.devetools + '/'
                              }}
                            </template>
                            <template v-if="goods.design.fileamount">{{
                                goods.design.fileamount + '个/'
                              }}
                            </template>
                            <template v-if="goods.design.filesize && goods.design.sizeunit">{{
                                goods.design.filesize + '' + goods.design.sizeunit + '/'
                              }}
                            </template>
                          </div>
                        </template>
                        <template v-if="goods.productType === 6">
                          <div class="fc-info fz-12">
                            ({{ goods.document.type }}-{{ goods.document.subType }})/
                            <template v-if="goods.document.encryptflag === 0">不加密/</template>
                            <template v-if="goods.document.encryptflag === 1">加密/</template>
                            <template v-if="goods.document.editflag === 1">可编辑/</template>
                            <template v-if="goods.document.editflag === 0">不可编辑/</template>
                            <template v-if="goods.document.watermarkflag === 0">无水印/</template>
                            <template v-if="goods.document.watermarkflag === 1">有水印/</template>
                            <template v-if="goods.document.docamount">{{
                                goods.document.docamount + '个/'
                              }}
                            </template>
                            <template v-if="goods.document.docpage">{{
                                goods.document.docpage + '页/'
                              }}
                            </template>
                            <template v-if="goods.document.docsize && goods.document.sizeunit">{{
                                goods.document.docsize + '' + goods.document.sizeunit + '/'
                              }}
                            </template>
                          </div>
                        </template>
                        <template
                          v-if="!order.refund || order.refund.status === 2 && order.state >= 2">
                          <!-- 没有申请退款  或者申请退款已被拒绝 并且订单已经支付成功 -->
                          <!-- 1.手动发货 2.网盘下载 3.网站下载 -->
                          <template v-if="goods.saleType === 1">
                            人工发货：{{ goods.sendEmail }}
                          </template>
                          <template v-if="goods.saleType === 2">
                            百度网盘下载：
                            <span class="mg-r10">下载地址：{{ goods.netdiscUrl }}</span>
                            <span class="mg-r10">密码：{{ goods.netdiscPwd }}</span>
                            <span class="mg-r10">解压密码：{{ goods.netdiscUnzipPwd }}</span>
                          </template>
                          <template v-if="goods.saleType === 3">
                            直接下载：
                            <a download :href="goods.siteCodePath">
                              <i class="el-icon-download"></i>
                            </a>
                          </template>
                        </template>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">¥{{ goods.price.toMoney() }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">
                        <template v-if="order.refund">
                          <template v-if="order.refund.status === 0">
                            <el-popover
                              placement="top"
                              title="退款理由"
                              width="400"
                              trigger="hover"
                              :content="order.refund.reqReason">
                              <el-tag slot="reference" size="mini" type="warning">
                                退款申请中
                              </el-tag>
                            </el-popover>
                          </template>
                          <template v-if="order.refund.status === 1">
                            <el-tag size="mini" type="primary">
                              退款完成
                            </el-tag>
                          </template>
                          <template v-if="order.refund.status === 2">
                            <el-tag size="mini" type="danger">
                              拒绝退款
                              <template v-if="order.refund.reason">
                                <el-tooltip class="item" effect="dark"
                                            :content="order.refund.reason" placement="top">
                                  <i class="el-icon-info"></i>
                                </el-tooltip>
                              </template>
                            </el-tag>
                          </template>
                        </template>
                        <template v-if="!order.refund || order.refund.status === 2">
                          <template v-if="order.state === 0">
                            <el-tag style="cursor: pointer;" size="mini">
                              待支付
                            </el-tag>
                          </template>
                          <template v-if="order.state === 1">
                            <el-tag style="cursor: pointer;" size="mini">
                              已取消
                            </el-tag>
                          </template>
                          <template v-if="order.state === 2">
                            <el-tag style="cursor: pointer;" size="mini">
                              待发货
                            </el-tag>
                          </template>
                          <template v-if="order.state === 3">
                            <el-tag style="cursor: pointer;" size="mini">
                              待收货
                            </el-tag>
                          </template>
                          <template v-if="order.state === 4">
                            <el-tag style="cursor: pointer;" size="mini">
                              待评价
                            </el-tag>
                          </template>
                          <template v-if="order.state === 5">
                            <el-tag style="cursor: pointer;" size="mini">
                              已完成
                            </el-tag>
                            <template v-if="order.customerServiceApply">
                              <template v-if="order.customerServiceApply.status === 1">
                                <div>待平台退款确认</div>
                              </template>
                            </template>
                          </template>
                          <template v-if="order.state === 0">
                            <div class="sctp-font-12 sctp-mar-t5">{{
                                order.createtimeToNow
                              }}
                            </div>
                          </template>
                          <template v-if="order.state === 3">
                            <count-down
                              :time="(order.deliverytime + 24 * 60 * 60 * 1000) - new Date().getTime()"></count-down>
                          </template>
                        </template>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">
                        {{ goods.amount }}
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="sctp-color-main sctp-tc">¥{{ order.paymoney }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-flex sctp-flex-center sctp-flex-column">
                        <template v-if="order.refund && order.refund.status !== 2">
                        </template>
                        <template v-else>
                          <template v-if="order.state === 4 || order.state === 1">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini" type="danger"
                                @click="orderOptions.onOptionsClick('delete', order)"
                              >
                                我要删除
                              </el-button>
                            </div>
                          </template>
                          <template v-if="order.state === 0">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                @click="orderOptions.onOptionsClick('pay', order)"
                                size="mini" type="warning"
                              >
                                立即支付
                              </el-button>
                            </div>
                          </template>
                          <template v-if="order.state === 0">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini"
                                @click="orderOptions.onOptionsClick('cancel', order)"
                              >
                                取消订单
                              </el-button>
                            </div>
                          </template>
                          <template
                            v-if="!order.refund && (order.state === 2) && shopGroup.goods[0].price">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini"
                                @click="orderOptions.onOptionsClick('requestRefund', order)"
                              >
                                申请退款
                              </el-button>
                            </div>
                          </template>
                          <template v-if="order.state === 3">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini" type="primary"
                                @click="orderOptions.onOptionsClick('confirmReceipt', order)"
                              >
                                确认收货
                              </el-button>
                            </div>
                          </template>
                          <template v-if="order.state > 2 && !order.isTransfer">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini"
                                @click="orderOptions.onOptionsClick('saleAfter', order)"
                              >
                                售后申请
                              </el-button>
                            </div>
                          </template>
                          <template v-if="order.state === 4">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                @click="orderOptions.onOptionsClick('evaluate', order)"
                                size="mini"
                              >
                                我要评价
                              </el-button>
                            </div>
                          </template>
                        </template>
                        <div class="sctp-mar-b5 sctp-tc">
                          <el-button
                            @click="orderOptions.onOptionsClick('detail', order)"
                            size="mini"
                          >
                            订单详情
                          </el-button>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </template>
            <template v-if="order.deliver_way">
              <div class="pad-tb10 pad-lr15">
                <span>发货方式：{{ {1: '邮箱', 2: '物流'}[order.deliver_way] }}</span>
                <template v-if="order.deliver_way === 2">
                  <span class="mg-l15">物流名称：{{ order.deliver_logistics }}</span>
                  <span class="mg-l15">物流单号：{{ order.logistics_order_no }}</span>
                </template>
                <template v-else-if="order.deliver_way === 1">
                  <span class="mg-l15">收货邮箱：{{ order.deliver_email }}</span>
                </template>
              </div>
            </template>
          </div>
        </template>
        </template>
        <template v-else>
          <div class="empty-text">暂无数据</div>
        </template>
      </div>
    </div>
    <div class="sctp-pad-tb15 sctp-flex sctp-flex-fe">
      <el-pagination
        :current-page="pageConfig.page"
        :page-size="pageConfig.limit"
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :page-sizes="pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="pageConfig.total">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import {order as orderApi} from "@/apis/index";
import DateUtil from "@/resources/js/dateutil";

const config = require('../../../resources/js/config');
export default {
  name: 'orderIndex',
  components: {
    CountDown: () => import('@CMP/common/CountDown'),
  },
  data() {
    return {
      isLoadData: false,
      searchForm: {
        orderNo: null,
        createTimeLimit: null,
        status: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      orderStatus: [
        {name: '待支付', value: 0, type: 'warning'},
        {name: '已取消', value: 1, type: ''},
        {name: '待发货', value: 2, type: ''},
        {name: '待收货', value: 3, type: ''},
        {name: '待评价', value: 4, type: ''},
        {name: '已完成', value: 5, type: ''}
      ],
      orderData: [],
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.page = 1;
          this.pageConfig.limit = limit;
          this.getOrder()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getOrder()
        }
      },
      orderOptions: {
        options: {
          delete: ({orderId}) => {
            this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
              type: 'warning'
            }).then(() => {
              orderApi.deleteOrder({
                orderId: orderId,
                userId: this.user.userId
              }).then(res => {
                this.$message.success('删除成功');
                this.getOrder();
              })
            })
          },
          pay: ({orderId}) => {
            this.$router.push({
              path: `/order/pay/${orderId}`
            })
          },
          cancel: ({orderId}) => {
            this.$confirm('确认取消订单?', '提示', {
              type: 'warning'
            }).then(() => {
              orderApi.cancelOrder({
                orderId: orderId,
                userId: this.user.userId
              }).then(res => {
                this.$message.success('取消订单成功');
                this.getOrder();
              })
            });
          },
          requestRefund: ({orderId}) => {
            this.$confirm('确认申请退款?', '提示', {
              type: 'warning'
            }).then(() => {
              this.goPage(`/order/refund/${orderId}`)
            })
          },
          confirmReceipt: ({orderId}) => {
            this.$confirm('请确认已经收到商品, 是否继续?', '提示', {
              type: 'warning'
            }).then(() => {
              orderApi.confirmReceipt({
                orderId: orderId,
                userId: this.user.userId
              }).then(res => {
                this.$message.success('确认收货成功');
                this.getOrder()
              })
            })
          },
          saleAfter: ({orderId}) => {
            this.$router.push({
              path: `/order/salesAfterRequest/${orderId}`
            })
          },
          evaluate: ({orderId}) => {
            this.$router.push({
              path: `/order/evaluate/${orderId}`
            })
          },
          detail: ({orderId}) => {
            this.openPage(`/order/orderDetail/${orderId}`);
          },
        },
        onOptionsClick: (option, order) => {
          let {orderid: orderId} = order;
          if (this.orderOptions.options[option]) {
            this.orderOptions.options[option].call(null, {orderId: orderId});
          }
        },
      }
    }
  },
  methods: {
    onSearchClick() {
      this.pageConfig.page = 1;
      this.getOrder()
    },
    changeOrderStatus(status) {
      this.searchForm.status = status;
      this.page = 1;
      this.getOrder()
    },
    setAllStatus(flag) {
      if (this.orderData) {
        this.orderData.forEach(order => {
          if (order.goods) {
            order.goods.forEach(shop => {
              if (shop.goods) {
                shop.goods.forEach(goods => {
                  goods.selected = !!flag
                })
              }
            })
          }
        })
      }
    },
    getOrder() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));

      if (this.searchForm.createTimeLimit && this.searchForm.createTimeLimit[0]) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 00:00:00');
        searchForm.createEnd = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 23:59:59');
        delete searchForm.createTimeLimit
      }
      this.isLoadData = true;
      orderApi.orderList({
        userId: this.user.userId,
        page: this.pageConfig.page,
        limit: this.pageConfig.limit,
        createStart: searchForm.createStart,
        createEnd: searchForm.createEnd,
        orderNo: searchForm.orderNo,
        status: searchForm.status
      }).then(res => {
        const {data, count = 0} = res;
        if (data) {
          data.forEach(order => {
            if (order.createtime) {
              order.createtimeToNow = DateUtil.formatDuring(
                new Date().getTime() - new Date(order.createtime).getTime());
            }
            if (order.goods) {
              order.goods.forEach(shop => {
                if (shop.goods) {
                  shop.goods.forEach(goods => {
                    goods.selected = false
                  })
                }
              })
            }
          });
          this.orderData = data
        }
        this.pageConfig.total = count
      })
      .finally(() => {
        this.isLoadData = false;
      });
    },
    reset(){
      this.$refs.searchForms.resetFields()
    }
  },
  computed: {
    orderStatusOperation() {
      return [
        {name: '全部', value: null},
        {name: '待支付', value: 0},
        {name: '已取消', value: 1},
        {name: '待发货', value: 2},
        {name: '待收货', value: 3},
        {name: '待评价', value: 4},
        {name: '已完成', value: 5},
      ]
    },
    activeOperation() {
      return (value) => {
        return value === this.searchForm.status
      }
    },
    selectAll() {
      let flag = false;
      if (this.orderData && this.orderData.length > 0) {
        flag = this.orderData.every(order => {
          if (order.goods && order.goods.length > 0) {
            return order.goods.every(shop => {
              if (shop.goods && shop.goods.length > 0) {
                return shop.goods.every(goods => {
                  return goods.selected
                })
              }
            })
          }
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false;
        if (this.orderData) {
          flag = this.orderData.some(order => {
            if (order.goods && order.goods.length > 0) {
              return order.goods.some(shop => {
                if (shop.goods && shop.goods.length > 0) {
                  return shop.goods.some(goods => {
                    return goods.selected
                  })
                }
              })
            }
          })
        }
        return flag
      }
    },
  },
  beforeMount() {
    this.getOrder()
  }
}
</script>

<style scoped>
.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

.order-item {
  padding-bottom: 15px;
  border-width: 1px;
  border-color: #e5e5e5;
  border-style: solid;
  /*margin: 15px 0;*/
  margin-bottom: 15px;
}

.el-button.active {
  color: #F90 !important;
  border-color: #F90 !important;
}
</style>
